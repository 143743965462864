import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, VSection } from "components/common"
import { PrimaryNav, MobileNav } from "src/components/navigation"
import {
  device,
  useContentfulComponent,
  useContentfulSection,
  useMeta,
} from "utils"

const PrivacyPolicyPage = ({ data: { pp } }) => {
  const {
    privacy_policy: { title, components },
  } = useContentfulSection(pp.sections)

  const { privacy_policy_body } = useContentfulComponent(components)

  const meta = useMeta(pp)

  return (
    <>
      <MobileNav />
      <PrimaryNav />
      <Layout>
        <Seo {...meta} />
        <Wrap>
          <VSection
            titleAs="h1"
            align="left"
            title={title}
            subtitle={privacy_policy_body.body}
          />
        </Wrap>
      </Layout>
    </>
  )
}

const Wrap = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--lightBG);
  --section-background-color: var(--lightBG);
  --section-align: center;
  --title-font-color: var(--card-shade_3);

  ul {
    padding-left: 20px;
    list-style: initial;
    color: var(--card-shade_3);
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.75rem;
    /* list-style-type: disc; */
    li {
      color: var(--card-shade_3);

      font-size: 0.875rem;
      line-height: 22px;
      @media ${device.laptop} {
        font-size: 1rem;
        line-height: 1.2;
      }
    }
  }

  table {
    border-collapse: collapse;
    border: 1px solid var(--card-shade_3);
    margin: 24px auto;

    th,
    td {
      border: 1px solid var(--card-shade_3);
      color: var(--card-shade_3);
      padding: 1rem;

      font-size: 0.875rem;
      line-height: 22px;
      @media ${device.laptop} {
        font-size: 1rem;
        line-height: 1.2;
      }
      p {
        font-size: 0.875rem;
        line-height: 22px;
        @media ${device.laptop} {
          font-size: 1rem;
          line-height: 1.2;
        }
      }
      /* min-width: 100px; */
    }

    ul {
      margin-top: 0.8rem;

      p {
        font-size: 0.875rem;
        line-height: 22px;
        @media ${device.laptop} {
          font-size: 1rem;
          line-height: 1.2;
        }
      }
    }
  }

  .markdown_body {
    overflow-x: auto;
    table {
      th,
      td {
        color: var(--card-shade_3);
        /* min-width: 100px; */
      }
    }
  }

  .v_tgContainer {
    --tg-max-width: 800px;
    --tg-text-align: left;

    --tg-title-color: var(--card-shade_3);
    --tg-subtitle-color: var(--card-shade_3);

    .title {
      text-align: center;
      margin-bottom: var(--sp-32);
    }
    .subtitle {
      white-space: break-spaces;
    }

    a {
      color: var(--link_light);
    }
  }
`

export const query = graphql`
  {
    pp: contentfulPage(pageId: { eq: "privacy_policy" }) {
      ...Page
    }
  }
`

export default PrivacyPolicyPage
